/**
 * Copyright (C) Sitevision AB 2002-2024, all rights reserved
 *
 * helpers related to images
 *
 * @author micke
 */
import sv from '@sv/core';

export const getImageDimension = function (options) {
  var maxWidth = options.maxWidth,
    minWidth = options.minWidth,
    maxHeight = options.maxHeight,
    minHeight = options.minHeight,
    ratio = 0,
    dimension = { width: options.width, height: options.height };

  if (dimension.width > maxWidth) {
    ratio = maxWidth / dimension.width;
    dimension.width = maxWidth;
    dimension.height = dimension.height * ratio;
  } else if (dimension.width < minWidth) {
    ratio = minWidth / dimension.width;
    dimension.width = minWidth;
    dimension.height = dimension.height * ratio;
  }

  if (dimension.height > maxHeight) {
    ratio = maxHeight / dimension.height;
    dimension.height = maxHeight;
    dimension.width = dimension.width * ratio;
  } else if (dimension.height < minHeight) {
    ratio = minHeight / dimension.height;
    dimension.height = minHeight;
    dimension.width = dimension.width * ratio;
  }

  return dimension;
};

export const changeImageSrcAndSize = function (element, url, width, height) {
  if (!document.body.classList.contains('sv-editing-mode')) {
    element.onload = function () {
      element.style.setProperty('max-width', width);
      element.style.setProperty('max-height', height);
      element.style.setProperty('aspect-ratio', 'auto ' + width + ' / ' + height);
    };
    element.src = url;
  }
};

sv.ImageUtil = {
  getImageDimension,
  changeImageSrcAndSize,
};
